<template>
  <el-container>
    <el-main style="margin:0;padding:0;">
      <el-container class="main-container" :class="{'pc':!isMobile(),'m':isMobile()}">
        <el-header height="188">
			<Myheader />
		</el-header>
        <el-main>
          <AppMain />
		  <el-footer>
			  <Myfooter />
		  </el-footer>
        </el-main>
        
      </el-container>
    </el-main>
  </el-container>
</template>

<script setup>
import Myheader from '@/layout/myheader.vue'
import Myfooter from '@/layout/myfooter.vue'
import { isMobile } from '@/utils/common.js'
import { ref,defineAsyncComponent,onMounted,getCurrentInstance } from 'vue'
import { useRoute,useRouter,onBeforeRouteUpdate } from 'vue-router'
// import useUserStore from '@/store/modules/user'
// import { getPartDetail } from '@/api/index'

const AppMain = defineAsyncComponent(() => import("./components/AppMain.vue"));

// const { proxy } = getCurrentInstance(); 
// const route = useRoute();
// const router = useRouter();
// const current = ref(route.meta.activeMenu);
// const showMenu = ref(false);

// const address = ref('长沙市芙蓉中路442号新湖南大厦事业楼1201');//公司地址
// const businessEmail = ref('liuwei@7pa.com');//合作邮箱
// const businessName = ref('刘蔚');//合作联系人

// onBeforeRouteUpdate((to) => {
// 	current.value = to.meta.activeMenu;
// 	showMenu.value = false;
// });

onMounted(()=>{
	// getPartDetail({ ifPage: 0, moduleId:50 }).then((res)=>{
	// 	res.rows.map((item) => {
	// 		if(item.title == '合作邮箱'){
	// 			businessEmail.value = proxy.filterHTML(item.textDescription);
	// 		}else if(item.title == '商务联系人'){
	// 			businessName.value = proxy.filterHTML(item.textDescription);
	// 		}else if(item.title == '联系地址'){
	// 			address.value = proxy.filterHTML(item.textDescription);
	// 		}
	// 	})
	// })
})

</script>

<style lang="less" scoped>
	.main-container{
		width: 100%;
		&.pc{
			min-width: 1280px;
			.el-main{
				height: calc(100vh - 85px);padding: 0;margin: 0;
			}
			
		}
		&.m{
			.el-main{
				height: calc(100vh - 85px);padding: 0;margin: 0;
			}
			
		}
		.el-header{
			--el-header-padding: 0px;
			
		}
		.el-footer{
			--el-footer-padding: 0px;
		}
		
		
		
	}
</style>